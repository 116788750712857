import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderAlternative } from "../components/header-alternative"
import { HeaderTitle, HeaderSubtitle } from "../components/header"
import Image404 from "../images/404.svg"
import css from "@emotion/css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <HeaderAlternative>
      <HeaderTitle>Page not found</HeaderTitle>
      <HeaderSubtitle
        css={css`
          margin-bottom: 50px;
        `}
      >
        We’re sorry but the page you are looking for does not exist.
      </HeaderSubtitle>
      <img src={Image404} className="img-responsive" alt="404 illustration" />
    </HeaderAlternative>
  </Layout>
)

export default NotFoundPage
